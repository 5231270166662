import { TenantValidationTypesConst } from '../types';

export const CONSTANTS = {
  API_BACKEND: process.env.REACT_APP_API_URL,
  WS_URL: process.env.REACT_APP_WS_URL,
  ENV: process.env.REACT_APP_ENV as
    | 'development'
    | 'staging'
    | 'production'
    | undefined,
  DISABLE_IN_PRO: process.env.REACT_APP_ENV === 'production',
  IS_PRODUCTION: process.env.REACT_APP_ENV === 'production',
  IS_DEVELOPMENT: process.env.REACT_APP_ENV === 'development',
  IS_STAGING: process.env.REACT_APP_ENV === 'staging',
  storeAccessTokenKey: '@smartpay_accessToken',
  storeRefreshTokenKey: '@smartpay_refreshToken',
  storeUserKey: '@smartpay_user',
  EMPLOYEEES_PAGINATE_LIMIT: 20,
  SALARIES_PAGINATE_LIMIT: 28,
  MATRIX_POSITIONS_PAGINATE_LIMIT: 20,
};

export const MONTHS = {
  PT: [
    {
      key: 'month-1',
      month_number: 1,
      month_number_original: 0,
      name: 'janeiro',
    },
    {
      key: 'month-2',
      month_number: 2,
      month_number_original: 1,
      name: 'fevereiro',
    },
    {
      key: 'month-3',
      month_number: 3,
      month_number_original: 2,
      name: 'março',
    },
    {
      key: 'month-4',
      month_number: 4,
      month_number_original: 3,
      name: 'abril',
    },
    {
      key: 'month-5',
      month_number: 5,
      month_number_original: 4,
      name: 'maio',
    },
    {
      key: 'month-6',
      month_number: 6,
      month_number_original: 5,
      name: 'junho',
    },
    {
      key: 'month-7',
      month_number: 7,
      month_number_original: 6,
      name: 'julho',
    },
    {
      key: 'month-8',
      month_number: 8,
      month_number_original: 7,
      name: 'agosto',
    },
    {
      key: 'month-9',
      month_number: 9,
      month_number_original: 8,
      name: 'setembro',
    },
    {
      key: 'month-10',
      month_number: 10,
      month_number_original: 9,
      name: 'outubro',
    },
    {
      key: 'month-11',
      month_number: 11,
      month_number_original: 10,
      name: 'novembro',
    },
    {
      key: 'month-12',
      month_number: 12,
      month_number_original: 11,
      name: 'dezembro',
    },
  ],
};

export const ERRORS_MESSAGE = {
  apiRequestError:
    'Houve algum problema ao tentar listar os dados. Por favor, contacte o administrador do sistema!',
};

export const TENANT_VALIDATION_TYPES: TenantValidationTypesConst = {
  GRADE_GHANGE: 'grade-structure-change-validator',
};
TENANT_VALIDATION_TYPES['GRADE_GHANGE'];

const PERMISSIONS_NAMES = [
  'head-menu@editar-perfil',
  'head-menu@notificacoes',

  'menu@home',
  'menu@seus-dados',
  'menu@estrutura-de-cargos',
  'menu@estrategia-de-remuneracao',
  'menu@dashboard-de-remuneracao',
  'menu@meritocracia',
  'menu@central-de-upload',
  'menu@controle-de-acesso',

  'sub-menu-seus-dados@dados-dos-funcionarios',
  'sub-menu-estrutura-de-cargos@visualizar-matriz',
  'sub-menu-estrategia-de-remuneracao@tabelas-salariais',
  'sub-menu-seus-dados@grades',
  'sub-menu-estrutura-de-cargos@criacao-de-matriz',
  'sub-menu-estrutura-de-cargos@descricao-de-cargo',
  'sub-menu-estrategia-de-remuneracao@dados-de-mercado',
  'sub-menu-seus-dados@dados-da-empresa',
  'sub-menu-estrategia-de-remuneracao@analise-de-competitividade',
  'sub-menu-seus-dados@politicas-de-meritocracia',
  'sub-menu-seus-dados@tabela-salarial',
  'sub-menu-estrutura-de-cargos@de-para-cargo',
  'sub-menu-estrategia-de-remuneracao@custo-de-enquadramento',
  'sub-menu-estrategia-de-remuneracao@alvos-de-incentivo',
  //grandes-numeros
  'grafico@custo-de-pessoal-resultado',
  'grafico@media-salarial-por-nivel',
  'grafico@folha-nominal-atual-mensal',
  'grafico@custo-da-folha-nominal-mensal',
  'grafico@massa-salarial-por-nivel',
  'grafico@orcamento-de-folha-nominal-anual',
  'grafico@posicionamento-na-faixa',
  'grafico@headcount-por-nivel',
  'grafico@headcount',
  //diversidade
  'relatorio-diversidade@classificacao',
  'relatorio-diversidade@genero-headcount',
  'relatorio-diversidade@cor-headcount',
  'relatorio-diversidade@media-salarial',
  'relatorio-diversidade@tempo-de-casa',
  'relatorio-diversidade@idade',
  'relatorio-diversidade@idade-admissao',
  //movimentacoes-salariais
  'relatorio-movimentacoes-salariais@saldo-de-desligamentos',
  //dispersao-tabela
  'grafico@dispersao-tabela',
  //dispersao-mercado
  'grafico@dispersao-mercado',

  'page-home@graficos',
  'page-seus-dados@dados-da-empresa',
  'page-seus-dados@dados-dos-funcionarios',
  'page-seus-dados@tabela-salarial-exportar',
  'page-estruturas-de-cargos@matriz-de-cargos-exportar',
  'page-estruturas-de-cargos@descricao-de-cargos-exportar',
  'page-estrategia-de-remuneracao@custo-de-enquadramento',
  'page-estrategia-de-remuneracao@dados-de-mercado-grade-exportar',
  'page-estrategia-de-remuneracao@analise-de-competitividade',
  'page-meritocracia',
  'page-controle-de-acesso@grupo',
  'page-meritocracia@exportar',
  'page-controle-de-acesso@usuario',
  'page-seus-dados@politicas-de-meritocracia',
  'page-seus-dados@area-de-funcionario',
  'page-estruturas-de-cargos@matriz-construcao',
  'page-estruturas-de-cargos@matriz-grade-contexto',
  'page-estruturas-de-cargos@de-para-de-cargos',
  'page-estrategia-de-remuneracao@tabela-salarial-faixa-ampla',
  'page-estrategia-de-remuneracao@custo-de-enquadramento-exportar',
  'page-estrategia-de-remuneracao@dados-de-mercado-cargo',
  'page-estrategia-de-remuneracao@analise-de-competitividade-exportar',
  'page-estrategia-de-remuneracao@alvos-de-incentivo',
  'page-dashboard-de-remuneracao@grandes-numeros',
  'page-dashboard-de-remuneracao@movimentacoes-salariais',
  'page-dashboard-de-remuneracao@analise-de-genero',
  'page-dashboard-de-remuneracao@dispersao-tabela',
  'page-dashboard-de-remuneracao@dispersao-mercado',
  'page-central-de-upload@template-de-tabela-salarial-download',
  'page-central-de-upload@template-de-tabela-salarial-upload',
  'page-central-de-upload@template-de-tabela-salarial-historico-upload',
  'page-central-de-upload@template-de-matching-de-cargos-download',
  'page-central-de-upload@template-de-matching-de-cargos-upload',
  'page-central-de-upload@template-de-matching-de-cargos-historico-upload',
  'page-central-de-upload@template-de-remuneracao-download',
  'page-central-de-upload@template-de-remuneracao-upload',
  'page-central-de-upload@template-de-remuneracao-historico-upload',
  'page-central-de-upload@template-de-historico-salarial-download',
  'page-central-de-upload@template-de-historico-salarial-upload',
  'page-central-de-upload@template-de-historico-salarial-historico-upload',
  'page-seus-dados@setor',
  'page-seus-dados@grades',
  'page-seus-dados@tabela-salarial',
  'page-estruturas-de-cargos@matriz-de-cargos',
  'page-estruturas-de-cargos@descricao-de-cargos',
  'page-estrategia-de-remuneracao@tabela-salarial-steps-fixos',
  'page-estrategia-de-remuneracao@dados-de-mercado-grade',
  'page-estrategia-de-remuneracao@dados-de-mercado-cargo-exportar',

  //valores
  'valores@benchmark',
  'valores@mercado-geral',

  'filter-areas', //GET /tenants/employees/areas , value = [area] (STRING)
  'filter-grades', // GET /tenants/positions/grades , value = [id] (STRING)
  'filter-tabelas', // GET /tenants/positions/grades/salaries/tables , value = [table_code] (STRING)
  'filter-empresas', // GET /tenants/employees/units , value = [company_unit] (STRING)
  'filter-diretorias', // GET /tenants/employees/directors , value = [director] (STRING)
] as const;

export type IPermissionNames = (typeof PERMISSIONS_NAMES)[number];
