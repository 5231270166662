import { createStitches } from '@stitches/react';

export function getRem(px: number) {
  return `${px / 16}rem`;
}

export const Stitches = createStitches({
  theme: {
    zIndices: {
      1: 9,
      2: 99,
      3: 999,
      4: 9999,
      5: 99999,
      6: 999999,
      7: 9999999,
    },
    colors: {
      primary: '#FF6E00',
      primaryClean: '#ED7D31',
      primaryDark: '#F2510B',
      secondary: '#8497B0',
      secondaryClean: '#D6DCE5',
      secondaryDark: '#44546A',
      red: '#F74545',
      link: '#3684B4',
      tableHead: '#3D5C88',
      border1: '#4F6B7D',
      white: '#FFFFFF',
      whiteSecond: '#FbFbFb',
      gray70: '#E6E5F3',
      gray80: '#E9ECF1',
      gray90: '#E7E7E7',
      gray100: '#A3A2A2',
      gray150: '#A29D9D',
      gray200: '#CFD3D9',
      gray300: '#58798D',
      gray400: '#44546A',
      gray600: '#6B6B6B',
    },
    space: {
      1: getRem(10),
      2: getRem(15),
      3: getRem(20),
      4: getRem(25),
      5: getRem(30),
      6: getRem(35),
      7: getRem(40),
      8: getRem(45),
      9: getRem(52),
      10: getRem(100),
      11: getRem(120),
      12: getRem(150),
    },
    sizes: {
      1: getRem(30),
      2: getRem(40),
      3: getRem(44),
      4: getRem(52),
      5: getRem(62),
      iconSmall: getRem(20),
      iconMedium: getRem(22),
      iconLarge: getRem(24),
      iconExtraLarge: getRem(28),
      sideBarFull: getRem(290),
      sideBarCollapsed: getRem(80),
      avatarSmall: getRem(34),
    },
    fontSizes: {
      main: getRem(32),
      title: getRem(24),
      titleSecondary: getRem(20),
      subtitle: getRem(16),
      body: getRem(15),
      large: getRem(45),
      medium: getRem(26),
      mediumsub: getRem(16),
      small: getRem(14),
      extraSmall: getRem(12),
    },
    fontWeights: {
      medium: 500,
      semiBold: 600,
      body: 400,
      bold: 700,
    },
    radii: {
      default: getRem(10),
      medium: getRem(5),
      large: getRem(20),
    },
    borderWidths: {
      1: getRem(1),
      2: getRem(2),
    },
    shadows: {
      default: '0px 0px 4px 4px rgba(0, 0, 0, 0.15)',
      strong: '0px 6px 4px rgba(0, 0, 0, 0.7)',
    },
  },
  media: {
    bp0: '(max-width: 540px)',
    bp1: '(min-width: 640px)',
    bp2: '(min-width: 768px)',
    bp3: '(min-width: 1024px)',
  },
});
